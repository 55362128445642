.backgroundLayout {
    height: 100vh;
    display: flex;
    align-items: center;
}
.backgroundLayout {
    background-image: url("../image/Background-img.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F5F6F8;
}
.backgroundLayout :global(.ant-tabs-nav::before) {
    border: none !important;
}
.backgroundLayout :global(.ant-tabs-nav-wrap) {
    display: none !important;
}

.main {
    width: 67rem;
    max-height: 66rem;
    padding: 10.5rem;
    margin-top: 14.5rem;
    border-radius: 1.5rem;
    background-color: #ffffff;
}

.main_title  {
    font-family: 'Montserrat', sans-serif;
    font-size: 3.2rem;
    line-height: 1.25;
    color: #3B4168;
    font-weight: 700;
    text-align: center;
}