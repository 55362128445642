.modalSuccess :global(.ant-modal-content) {
    background: #51D54F;
    border-radius: 0.4rem;
}

.modalSuccess :global(.ant-modal-close-x) {
    display: none;
}

.modalSuccess h1 {
    color: white;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;
}
.prefix path {
    fill: white;
}
.prefix svg {
    margin: 0 1rem;
    width: 1.693rem;
    height: 1.693rem;
}

.modalSuccessWrapper {
    display: flex;
    align-items: center;
}