.colWidth {
    width: 100%;
}

.colWidth :global(.ant-input) {
    padding: 0.8rem 1.5rem;
    border-radius: 0.4rem;
    outline: none;
    transition: .3s;
    width: 100%;
    border: 0.1rem solid #E4E7F2;
}

.title {
    margin-bottom: 2.5rem;
    text-align: center;
}

.title__text {
    font-size: 1.6rem;
    line-height: 1.25;
    color: #3B4168;
}

.colWidth :global(.ant-form-item) {
    margin-bottom: 1.6rem;
}

.forgotPassword {
    text-align: center;
    margin-bottom: 4rem;
}

.forgotPassword__link {
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.25;
    color: #0071CE;
    font-weight: 500;
    height: auto;
}

._errors {
    display: flex;
    justify-content: center;
}

.buttonLogin {
    border-radius: 0.4rem !important;
    height: 4.5rem !important;
}