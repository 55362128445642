._body {
    width: 100%;
    margin: 0 auto;
    padding: 0 2.8rem;
}

._table {
    width: 100%;
}

.passportBlock {
    max-width: 25rem;
}

._bodyHeader {
    text-align: center;
    width: 100%;
    margin: 6rem 0 0 0;
}

._bodyHeaderChildBlock {
    text-align: center;
}

._buttonBack {
    margin-top: 2.2rem;
    color: #0071CE;
}

._forChange {
    font-size: 1.4rem;
    color: #6F7C98;
    line-height: 1.428;
    font-style: normal;
    font-weight: 400;
    margin-top: 1.4rem;
}

._listUser {
    font-size: 2.4rem;
    color: #3B4168;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 1.166;
}

._body :global(.ant-input-affix-wrapper) {
    display: flex;
    justify-content: flex-start;
    width: 48.5rem;
    height: 4rem;
    margin: 3.7rem 0 3.2rem;
    color: #909EBB;
    font-size: 1.6rem;
    line-height: 1.166;
}

.deleteBtn {
    color: #BDBDBD;
}
.editBtn {
    width: 2.4rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
}

.editBtn svg {
    color: #BDBDBD;
    margin-right: 1.2rem;
    margin-top: 0.4rem;
    width: 0.4rem;
    height: 2rem;
}

.editBtn:hover {
    background: #E0F1FF;
    transition: 0.5s;
}

.tableFooter {
    display: flex;
    width: 99%;
    justify-content: space-between;
    margin-top: 4rem;
    color: #909EBB;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.166;
    padding-bottom: 4rem;
}

.tableFooterChildBlock {
    display: flex;
}

.select {
    margin-right: 4.2rem;
}

.paginationCounter {
    margin-top: 0.6rem;
}

.tableFooter span {
    color: #0071CE;
}

._body :global(.ant-tag) {
    background-color: #F5FBFF;
    color: #909EBB;
    font-size: 1.2rem;
    line-height: 1;
    font-style: normal;
    font-weight: 400;
    border: none;
    padding: 0.2rem 1rem 0.5rem;
    margin-top: 1.2rem;
}

.tagsNone {
    display: none;
}

.record {
    margin-top: 0.7rem;
}

.ellipseDrots {
    position: relative;
    top: -0.4rem;
    left: 1.44rem;
}

.prefix {
    width: 1.693rem;
    height: 1.693rem;
}

.arrow_left {
    border: solid #BDBDBD;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transition: 0.2s linear;
}

.arrow_right {
    border: solid #BDBDBD;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: 0.2s linear;
}

.arrow {
    display: flex;
}

.arrow p {
    margin-left: 2.2rem;
    margin-top: 0.3rem;
    cursor: pointer;
}

.columnRight {
    text-align: right !important;
}

.tableThead th {
    border-bottom: 1px solid #99C6EB;
    color: #6F7C98;
    font-size: 1.6rem;
    line-height: 1.166;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    background: #fff;
    padding: 1.6rem 1.6rem;
}

.tableTbody:hover {
    background-color: #F5FBFF;
}

.tableTbody td {
    border-bottom: 1px solid #E4E7F2;
    color: #3B4168;
    font-size: 1.6rem;
    line-height: 1.166;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    padding: 1.6rem 1.6rem;
}

.deleteBtn {
    margin-left: 2.2rem;
}

.deleteBtn svg {
    margin-bottom: 0.2rem;
}

.arrow_right:hover {
    border: solid #0071CE;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.arrow_left:hover {
    border: solid #0071CE;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

._body :global(.ant-pagination-item) {
    display: none;
}

._body :global(.ant-select-selector) {
    border: none !important;
    margin-left: 0.5rem;
}

._body :global(.ant-select-arrow .anticon svg) {
    height: 0.8rem;
    margin-top: 0.2rem;
}

._body :global(.ant-table-pagination) {
    position: absolute;
    right: 0;
    bottom: -8.5rem;
    display: none;
}


._body :global(.ant-table-thead tr th) {
    color: #6F7C98;
    font-size: 1.6rem;
    line-height: 1.166;
    font-style: normal;
    font-weight: 400;
    text-align: left !important;
    background: #fff;
    border-bottom: 1px solid #99C6EB;
}

._body :global(.ant-table-tbody tr td) {
    color: #3B4168;
    font-size: 1.6rem;
    line-height: 1.166;
    font-style: normal;
    font-weight: 400;
    /* text-align: left !important; */
}

._body :global(*::before) {
    display: none;
}

._body :global(.ant-pagination-prev .ant-pagination-item-link) {
    border: none;
}

._body :global(.ant-pagination-next .ant-pagination-item-link) {
    border: none;
    width: 0.75rem;
    height: 1.167rem;
}


._body :global(.ant-input-prefix) {
    position: absolute;
    right: 1.14rem;
    top: 1.167rem;
}

._body :global(.ant-input) {
    width: 43rem;
}

.avatarBlock img {
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 100%;
}

.activeStatus {
    color: #73D071 !important;
}

.deletedStatus {
    color: #EB5757 !important
}

.blockedStatus {
    color: #909EBB !important
}

/* .phoneBlock {
    display: flex;
}

.phoneBlockIcon {
    margin: 0.4rem 1.1rem 0.4rem 0;
} */


.avatarIcon {
    width: 3.6rem;
    height: 3.6rem;
}

.actionBtn {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

@media all and (max-width: 1690px) {
    ._table {
        width: 1690px;
    }

    ._tableBlock {
        overflow-x: scroll;
    }
}