.modalView :global(.ant-modal-content) {
    width: 54rem;
}

.modalView :global(.ant-modal-close) {
    font-size: 1.9rem;
    margin-top: 2.2rem;
    margin-right: 3.5rem;
}

.modalView :global(.ant-modal-title) {
    color: #3B4168;
    font-size: 2.4rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.modalView :global(.ant-modal-footer) {
    padding: 0 3.2rem;
    border: none;
}

.modalView :global(.ant-modal-header) {
    padding: 4rem 4rem 2.5rem;
    border: none;
}

.modalView :global(.ant-modal-body) {
    padding: 0 4rem 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalView p {
    margin: 4rem 0 1.6rem;
    color: #3B4168;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
}

.modal_col {
    margin-bottom: 2.4rem;
}

._line {
    width: 100%;
    border-bottom: 0.1rem solid #E4E7F2;
    margin-bottom: 3.2rem;
}

.blueText_WithIcon {
    color: #0071CE;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 0;
}

.save {
    border-radius: 0.4rem !important;
    padding: 1.2rem 2.4rem !important;
    height: 4.4rem !important;
    margin: 2.4rem 0 3rem 0.8rem !important;
}

._modalBtnCalcel {
    border-radius: 0.4rem;
    padding: 1.2rem 2.4rem;
    height: 4.4rem;
    margin: 2.4rem 0.8rem 3rem 0;
}

._line_footer {
    width: 100%;
    border-bottom: 0.1rem solid #E4E7F2;
    margin-bottom: 0;
}