.modalView :global(.ant-modal-content) {
    width: 54rem;
    /* height: 40.05rem; */
}

.modalView :global(.ant-modal-close) {
    font-size: 1.9rem;
    margin-top: 2.2rem;
    margin-right: 2rem;
}

.modalView :global(.ant-modal-title) {
    color: #3B4168;
    font-size: 2.4rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.marginBottom {
    margin-bottom: 0.8rem;
}

.modalView :global(.ant-modal-header) {
    padding: 4rem 4rem 2.5rem;
    border: none;
}

.modalView :global(.ant-modal-footer) {
    padding: 0 3.2rem;
    border: none;
}

.modalView :global(.ant-modal-body) {
    padding: 0 4rem 2.8rem;
}

.modal_col {
    margin-bottom: 2.4rem;
}

.modalView h6 {
    font-size: 1.4rem;
    font-weight: 400;
    color: #6F7C98;
    margin-bottom: 0.8rem;
}

.modalView h3 {
    color: #3B4168;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
}


.workspaceDocument {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F5FBFF;
    height: 4.4rem;
    margin-bottom: 1.6rem;
    padding: 0 1.4rem;
}

.workspaceDocument__inner {
    display: flex;
}

._line {
    width: 100%;
    border-bottom: 0.1rem solid #E4E7F2;
    margin-bottom: 3.2rem;
}

._marginRight {
    margin-right: 1rem;
    margin-top: 0.3rem;
    color: #0071CE;
}

.blueText_WithIcon {
    color: #0071CE;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 0;
}

.select :global(.ant-select-selector) {
    height: 4rem !important;
    border-radius: 0.4rem !important;
    display: flex;
    align-items: center;
}

.select :global(.ant-select-selection-placeholder) {
    color: #3B4168;
    font-size: 1.6rem;
    font-weight: 400;
}

.rejectBtn {
    border-radius: 0.4rem !important;
    padding: 1.2rem 2.4rem !important;
    height: 4.2rem !important;
    margin: 2.4rem 0 3rem 0.8rem !important; 
}

.cancel {
    border-radius: 0.4rem;
    padding: 1.2rem 2.4rem;
    height: 4.4rem;
    margin: 2.4rem 0.8rem 3rem 0;
}
._line_footer {
    width: 100%;
    border-bottom: 0.1rem solid #E4E7F2;
    margin-bottom: 0;
}
.modalInp{
    height: 4rem;
    border-radius: 0.4rem;
}